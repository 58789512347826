import React from "react";

const Page404 = React.lazy(() => import("../Pages/404"));
const LoginTelegram = React.lazy(() => import("../Pages/Login/Telegram"));
const GameProfile = React.lazy(() => import("../Pages/Game/GamerProfile"));
const Main = React.lazy(() => import("../Pages/Main"));
const SettingsBaseProp = React.lazy(() =>
  import("../Pages/Admin/Settings/BaseProp")
);
const AllObjects = React.lazy(() =>
  import("../Pages/Admin/Settings/Objects/AllObjects")
);
const OneObject = React.lazy(() =>
  import("../Pages/Admin/Settings/Objects/AllObjects/OneObject")
);
const AdminPage = React.lazy(() => import("../Pages/Admin"));
const PlayerList = React.lazy(() =>
  import("../Pages/Admin/Players/PlayerList")
);
const PlayerOne = React.lazy(() =>
  import("../Pages/Admin/Players/PlayerList/PlayerOne")
);
const Rewards = React.lazy(() => import("../Pages/Admin/Settings/Rewards"));
const Guilds = React.lazy(() => import("../Pages/Admin/Settings/Guilds"));
const Guild = React.lazy(() =>
  import("../Pages/Admin/Settings/Guilds/components/Guild")
);
const Buildings = React.lazy(() =>
  import("../Pages/Admin/Settings/Guilds/components/Buildings")
);
const Building = React.lazy(() =>
  import("../Pages/Admin/Settings/Guilds/components/Building")
);
const AdminStore = React.lazy(() =>
  import("../Pages/Admin/Settings/Guilds/components/Buildings/components/Store")
);
const AdminStoreObject = React.lazy(() =>
  import(
    "../Pages/Admin/Settings/Guilds/components/Buildings/components/StoreObject"
  )
);
const Buffs = React.lazy(() =>
  import("../Pages/Admin/Settings/Guilds/components/Buffs")
);
const Events = React.lazy(() => import("../Pages/Admin/Settings/Events"));
const OneEvent = React.lazy(() =>
  import("../Pages/Admin/Settings/Events/OneEvent")
);
const Recipe = React.lazy(() => import("../Pages/Admin/Settings/Recipe"));
const OneRecipe = React.lazy(() =>
  import("../Pages/Admin/Settings/Recipe/OneRecipe")
);
const Rating = React.lazy(() => import("../Pages/Site/Rating"));
const RatingTable = React.lazy(() =>
  import("../Pages/Site/Rating/RatingTable")
);

const WikiList = React.lazy(() => import("../Pages/Site/WikiList"));
const OneWiki = React.lazy(() => import("../Pages/Site/OneWiki"));
const AdminWikiEdit = React.lazy(() => import("../Pages/Admin/WikiEdit"));

export const ListRoutes = [
  { element: <Page404 />, path: "*", private: false, adminPage: false },
  { element: <Main />, path: "/", private: false, adminPage: false },
  {
    element: <Rating />,
    path: "/rating",
    private: false,
    adminPage: false,
    linkName: "Рейтинг",
    isMainMenu: true,
  },
  {
    element: <RatingTable />,
    path: "/rating/:chkey",
    private: false,
    adminPage: false,
  },
  // {
  //   element: <WikiList />,
  //   path: "/wiki",
  //   private: false,
  //   adminPage: false,
  //   linkName: "Вики",
  //   isMainMenu: true,
  // },
  // {
  //   element: <OneWiki />,
  //   path: "/wiki/:id",
  //   private: false,
  //   adminPage: false,
  // },

  // {
  //   element: <AdminWikiEdit />,
  //   path: "/admin/publications/:category/:id",
  //   private: true,
  //   adminPage: true,
  // },
  // {
  //   element: <RatingPage />,
  //   path: "/ideas",
  //   private: false,
  //   adminPage: false,
  //   linkName: "Идеи",
  //   isMainMenu: true,
  // },
  {
    element: <AdminPage />,
    path: "/admin",
    private: true,
    adminPage: true,
    linkName: "Админка",
    isMainMenu: true,
  },
  {
    element: <SettingsBaseProp />,
    path: "/admin/settings/baseprop",
    private: true,
    adminPage: true,
  },
  {
    element: <AllObjects />,
    path: "/admin/settings/objects/all",
    private: true,
    adminPage: true,
  },
  {
    element: <OneObject />,
    path: "/admin/settings/objects/:id_object",
    private: true,
    adminPage: true,
  },

  {
    element: <PlayerList />,
    path: "/admin/settings/players",
    private: true,
    adminPage: true,
  },
  {
    element: <PlayerOne />,
    path: "/admin/settings/players/:userTelegramId/:panel",
    private: true,
    adminPage: true,
  },
  {
    element: <Rewards />,
    path: "/admin/settings/rewards",
    private: true,
    adminPage: true,
  },
  {
    element: <Guilds />,
    path: "/admin/settings/guilds",
    private: true,
    adminPage: true,
  },
  {
    element: <Guild />,
    path: "/admin/settings/guild/:id",
    private: true,
    adminPage: true,
  },
  {
    element: <Buildings />,
    path: "/admin/settings/guilds/buildings",
    private: true,
    adminPage: true,
  },
  {
    element: <Building />,
    path: "/admin/settings/guilds/building/:id",
    private: true,
    adminPage: true,
  },
  {
    element: <AdminStore />,
    path: "/admin/settings/guilds/buildings/store",
    private: true,
    adminPage: true,
  },
  {
    element: <AdminStoreObject />,
    path: "/admin/settings/guilds/buildings/store/objects/:id",
    private: true,
    adminPage: true,
  },
  {
    element: <Buffs />,
    path: "/admin/settings/guilds/buffs",
    private: true,
    adminPage: true,
  },
  {
    element: <Events />,
    path: "/admin/settings/events",
    private: true,
    adminPage: true,
  },
  {
    element: <OneEvent />,
    path: "/admin/settings/events/:id_event",
    private: true,
    adminPage: true,
  },
  {
    element: <Recipe />,
    path: "/admin/settings/recipe",
    private: true,
    adminPage: true,
  },
  {
    element: <OneRecipe />,
    path: "/admin/settings/recipe/:id_recipe",
    private: true,
    adminPage: true,
  },

  {
    element: <LoginTelegram />,
    path: "/auth/telegram/:id_telegram/:guid",
    private: false,
    adminPage: false,
  },
  {
    element: <GameProfile />,
    path: "/game/profile/me/:panel",
    private: true,
    adminPage: false,
  },
];
