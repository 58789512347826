import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import MainMenu from "./MainMenu";
import cl from "./header.module.css";
import { Link, useLocation } from "react-router-dom";
import { useAuthHook } from "../../Hooks/Auth.hook";

const { Header: HeaderAnt } = Layout;

export const Header = () => {
  const { token } = useAuthHook();
  const { pathname } = useLocation();
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const bannerHeight = document
    ?.getElementsByTagName("section")
    ?.item(0)?.scrollHeight;

  const handleScroll = () => {
    const scrollY = window.scrollY;

    if (scrollY >= bannerHeight) {
      setBackgroundColor("var(--primary-background)");
    } else {
      setBackgroundColor("transparent");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <HeaderAnt
      className={cl.headerAnt}
      style={{
        padding: 0,
        backgroundColor:
          pathname === "/" || pathname === "" ? backgroundColor : "",
      }}
    >
      <div className={cl.headerContent}>
        <div className={cl.leftBlock}>
          <Link to={process.env.REACT_APP_GAME_BOT_LINK} target={"_blank"}>
            <span className={cl.logoTextSmall}>Играть</span>
          </Link>

          {token() && (
            <Link to="/game/profile/me/cloth">
              <span className={cl.logoTextSmall}>Профиль</span>
            </Link>
          )}
        </div>

        <Link to={"/"} className={cl.logoWrapper}>
          <span
            className={`${cl.logoText} ${
              pathname === "/" || pathname === "" ? cl.isMainPage : ""
            }`}
          >
            ST
          </span>
        </Link>

        <MainMenu />
      </div>
    </HeaderAnt>
  );
};
