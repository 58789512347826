import { useCallback, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const storageName = "userInfo";

export const useAuthHook = () => {
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();

  const auth = useCallback((data) => {
    const val = jwtDecode(data.token);

    console.log(val);

    localStorage.setItem(
      storageName,
      JSON.stringify({
        token: data.token,
        userId: val.id,
        userName: val.name,
        exp: val.exp,
      })
    );

    navigate("/game/profile/me/cloth");
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem(storageName);
  }, []);

  const idTelegram = useCallback(() => {
    const ll = localStorage.getItem(storageName);
    if (ll) {
      return JSON.parse(ll).userId;
    } else {
      return "";
    }
  }, []);

  const token = useCallback(() => {
    const ll = localStorage.getItem(storageName);
    if (ll) {
      return JSON.parse(ll).token;
    } else {
      return "";
    }
  }, []);

  const userName = useCallback(() => {
    const ll = localStorage.getItem(storageName);
    if (ll) {
      return JSON.parse(ll).userName;
    } else {
      return "";
    }
  }, []);

  function contains(arr, elem) {
    return arr.indexOf(elem) !== -1;
  }

  const isAdmin = useCallback(() => {
    const ll = localStorage.getItem(storageName);

    if (ll) {
      return contains(jwtDecode(JSON.parse(ll)?.token)?.roles, "ADMIN");
    } else {
      return false;
    }
  }, []);

  useEffect(() => {
    const loginUser = async () => {
      const data = await JSON.parse(localStorage.getItem(storageName));
      if (!data || data?.exp < Date.now() / 1000) {
        logout();
      }

      setReady(true);
    };

    void loginUser();
  }, [logout, navigate]);

  return { auth, logout, idTelegram, token, userName, isAdmin, ready };
};
