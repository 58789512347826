import { Layout, Rate } from "antd";
import QRCodeDonate from "../../Components/QRCodeDonate";
const { Footer } = Layout;

export const FooterPage = () => {
  return (
    <Footer
      style={{
        textAlign: "center",
        background: "#fff",
        fontSize: 14,
      }}
    >
      <a href={"https://t.me/skytower_game_bot"}>Переходи в игру</a>
      <br />
      Телеграмм игра "Небесная Башня" ©2023 Created by{" "}
      <a href="https://taskgroup.ru" target="_blank" rel="noreferrer">
        TaskGroup
      </a>
      <br />
      <QRCodeDonate />
      <Rate disabled defaultValue={4} />
    </Footer>
  );
};
