import {
  ADD_ITEM_FOR_TRADE,
  CLEAR_TRADE,
  DELETE_ITEM_FROM_TRADE,
  CHANGE_COUNT_ITEMS_IN_TRADE,
} from "./type";

const storageName = "trade";
const data = JSON.parse(localStorage.getItem(storageName)) ?? { trade: [] };

const initState = {
  items: data.trade,
};

export const tradeReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_ITEM_FOR_TRADE: {
      const result = [...state.items, { ...action.payload }];
      localStorage.setItem(storageName, JSON.stringify({ trade: result }));
      return { ...state, items: result };
    }
    case CLEAR_TRADE: {
      localStorage.removeItem(storageName);
      return { ...state, items: [] };
    }
    case DELETE_ITEM_FROM_TRADE: {
      const result = state.items.filter(
        (item) => item.id_object !== action.payload
      );
      localStorage.setItem(storageName, JSON.stringify({ trade: result }));
      return { ...state, items: result };
    }
    case CHANGE_COUNT_ITEMS_IN_TRADE: {
      const index = state.items.findIndex(
        (item) => item.id_object === action.payload.id
      );
      const before = state.items.slice(0, index);
      const after = state.items.slice(index + 1);
      const result = [
        ...before,
        { ...state.items[index], number: action.payload.count },
        ...after,
      ];
      localStorage.setItem(storageName, JSON.stringify({ trade: result }));
      return { ...state, items: result };
    }
    default:
      return state;
  }
};
