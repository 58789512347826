import { useState, useEffect } from "react";
import cl from "./Up.module.css";
import { Button } from "antd";
import { UpOutlined } from "@ant-design/icons";

export const Up = () => {
  const [opacity, setOpacity] = useState(0);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const innerHeight = window.innerHeight;

    if (scrollY >= innerHeight) {
      setOpacity(1);
    } else {
      setOpacity(0);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Button
      icon={<UpOutlined />}
      className={cl.up}
      style={{ opacity }}
      onClick={() => scrollToTop()}
      aria-label="Наверх"
    />
  );
};
