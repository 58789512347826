import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { ListRoutes } from "../../../Routes/Listroutes";
import { useAuthHook } from "../../../Hooks/Auth.hook";
import cl from "./menu.module.css";

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const MainMenu = () => {
  const location = useLocation();
  const [items, setItems] = useState([]);
  const { isAdmin, idTelegram } = useAuthHook();
  const isAdm = isAdmin();
  const idTG = idTelegram();
  const [current, setCurrent] = useState(
    items
      ? items.find((item) => location.pathname.indexOf(item.key))?.key ?? ""
      : ""
  );

  useEffect(() => {
    ListRoutes?.filter((lr) => lr.isMainMenu).map((fn) => {
      if (
        (fn.adminPage && isAdm) ||
        (!fn.adminPage && fn.private && idTG > 0) ||
        !(fn.adminPage || fn.private)
      ) {
        setItems((prev) => [
          ...prev,
          {
            key: fn.path,
            label: fn.linkName,
            title: fn.linkName,
          },
        ]);
      }

      return fn;
    });
  }, [idTG, isAdm]);

  useEffect(() => {
    const el = items?.find(
      (item) => location.pathname.indexOf(item.key) !== -1
    );

    setCurrent(el?.key ?? "");
  }, [location.pathname, items]);

  return (
    <Menu
      theme="dark"
      mode="horizontal"
      onClick={(e) => setCurrent(e.key)}
      selectedKeys={[current]}
      style={{
        width: "45%",
        height: 61,
        backgroundColor: "transparent",
      }}
      items={items?.map((item) =>
        getItem(
          <Link to={item.key}>
            <span className={cl.menuItem}>{item.title}</span>
          </Link>,
          item.key
        )
      )}
    />
  );
};

export default MainMenu;
