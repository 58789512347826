import { Routes, Route } from "react-router-dom";
import { ListRoutes } from "./Listroutes";
import { useAuthHook } from "../Hooks/Auth.hook";
import React, { Suspense, useEffect } from "react";

export const useRoutes = () => {
  const { isAdmin, idTelegram } = useAuthHook();
  const isAdm = isAdmin();
  const idTG = idTelegram();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense>
      <Routes>
        {ListRoutes.map((route, index) => {
          if (route.adminPage && isAdm) {
            return (
              <Route element={route.element} path={route.path} key={index} />
            );
          } else if (!route.adminPage && route.private && idTG > 0) {
            return (
              <Route element={route.element} path={route.path} key={index} />
            );
          } else if (!(route.adminPage || route.private)) {
            return (
              <Route element={route.element} path={route.path} key={index} />
            );
          } else return null;
        })}
      </Routes>
    </Suspense>
  );
};
