import React, { useState } from "react";
import { Button, Popover, QRCode } from "antd";

const QRCodeDonate = () => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <div style={{ margin: "10px 0" }}>
      <Popover
        content={
          <>
            <a
              href="https://www.tinkoff.ru/cf/7g76iJ6gp8C"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "var(--primary-color)",
                width: "100%",
                textAlign: "center",
              }}
            >
              Ссылка на страницу помощи
            </a>
            <QRCode
              style={{ margin: "0 auto" }}
              value="https://www.tinkoff.ru/cf/7g76iJ6gp8C"
            />
          </>
        }
        title={
          <span style={{ width: "100%", textAlign: "center" }}>
            Спасибо за помощь проекту
          </span>
        }
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button size="large" type="primary" style={{ width: "100%" }}>
          Поддержка проекта
        </Button>
      </Popover>
    </div>
  );
};

export default QRCodeDonate;
