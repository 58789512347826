import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ConfigProvider } from "antd";
import ru_RU from "antd/lib/locale/ru_RU";
import moment from "moment";
import { rootReducer } from "./Redux/rootReducer";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  // enhancers: compose(applyMiddleware(thunk)),
});

moment.locale("ru");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    locale={ru_RU}
    moment={moment}
    theme={{
      token: {
        colorPrimary: "#ecb563",
        fontFamily: "Ubuntu",
      },
    }}
  >
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </ConfigProvider>
);
