import { LoadingOutlined } from "@ant-design/icons";

export const Loader = (props) => {
  const { style } = props;

  return (
    <LoadingOutlined
      {...props}
      style={{
        fontSize: 64,
        fontWeight: 400,
        color: "var(--primary-color)",
        ...style,
      }}
    />
  );
};
