import { App as AppAntd } from "antd";
import { useRoutes } from "./Routes/Routes";
import { Header } from "./Layout/Header";
import { FooterPage } from "./Layout/Footer";
import { Layout } from "antd";
import { useLocation } from "react-router-dom";
import { useAuthHook } from "./Hooks/Auth.hook";
import { Loader } from "./Components/UI/Loader/Loader";
import { Up } from "./Components/UI/Up/Up";
const { Content } = Layout;

function App() {
  const { ready } = useAuthHook();
  const router = useRoutes();
  const { pathname } = useLocation();

  if (!ready) {
    return (
      <section className="loadingPage">
        <Loader />
      </section>
    );
  }

  return (
    <AppAntd>
      <Layout>
        {!(pathname.indexOf("/game") === 0) && <Header />}

        <Layout>
          <Content
            className={`contentBody ${
              pathname === "/"
                ? "whiteBackground pad-0"
                : pathname.indexOf("/game") === -1
                ? "whiteBackground"
                : "game"
            }`}
          >
            {router}
          </Content>
        </Layout>

        {(pathname === "/" || pathname === "") && <Up />}

        {!(
          pathname.indexOf("/game") === 0 || pathname.indexOf("/admin") === 0
        ) && <FooterPage />}
      </Layout>
    </AppAntd>
  );
}

export default App;
